import Component from 'navigation/component/Component'
import './VideoPlayer.scss'
import { bindMethod } from 'helpers/bind'
import browser from 'helpers/browser'
import mqStore from 'stores/mqStore'
export type VideoPlayerType = {
  refs:{
    video: HTMLVideoElement
    pause: HTMLButtonElement
    mute: HTMLButtonElement
    fullscreen: HTMLButtonElement
    timecode: HTMLSpanElement
    progress: HTMLDivElement
    progressWrapper: HTMLDivElement
    duration: HTMLSpanElement
  }
}
class VideoPlayer extends Component<VideoPlayerType> {
  // private autoScrolling: boolean = false
  private _playing: boolean = false
  private _muted: boolean = true
  private _fullscreen: boolean = false

  private set playing (value: boolean) {
    this._playing = value
    this.el.classList.toggle('playing', value)
    if (this.refs.pause) this.refs.pause.children[0].textContent = value ? 'Pause' : 'Play'
  }

  private set muted (value: boolean) {
    this._muted = value
    this.el.classList.toggle('muted', value)
    if (this.refs.mute) this.refs.mute.children[0].textContent = value ? 'Unmute' : 'Mute'
  }

  private set fullscreen (value: boolean) {
    this._fullscreen = value
    this.el.classList.toggle('fullscreen', value)
    if (this.refs.fullscreen) this.refs.fullscreen.children[0].textContent = value ? 'Exit' : 'Fullscreen'
  }

  private get playing (): boolean {
    return this._playing
  }

  private get muted (): boolean {
    return this._muted
  }

  private get fullscreen (): boolean {
    return this._fullscreen
  }

  private moving: boolean = false
  private sources: HTMLSourceElement[] = []

  constructor (el: HTMLElement) {
    super(el)
    this.bindRefs()
  }

  initialized (): void {
    super.initialized()
    this.sources = Array.from(this.refs.video.querySelectorAll('source'))
    if (this.refs.video.dataset.autoplay === 'true')
      this.refs.video.play()
  }

  bindEvents (add: boolean): void {
    const method = bindMethod(add)
    this.refs.video?.[method]('timeupdate', this.handleTimeUpdate)
    this.refs.video?.[method]('playing', this.handlePlay)
    this.refs.video?.[method]('pause', this.handlePause)
    this.refs.video?.[method]('loadeddata', this.handleLoadedMetadata)
    this.refs.pause?.[method]('click', this.handlePlayPause)
    this.refs.mute?.[method]('click', this.handleMuteUnmute)
    this.refs.fullscreen?.[method]('click', this.handleFullscreen)
    // listen to exitfullscreen event
    document?.[method]('fullscreenchange', this.handleFullscreenChange)
    this.refs.progressWrapper?.[method]('click', this.handleProgressClick)
  }

  handleLoadedMetadata = () => {
    if (this.refs.duration) this.refs.duration.textContent = this.formatTime(this.refs.video.duration)
  }

  handleProgressClick = (e: Event) => {
    this.moving = true
    e.stopImmediatePropagation()
    e.stopPropagation()
    e.preventDefault()
    const progress = this.refs.progressWrapper
    const video = this.refs.video
    const value = ((e as MouseEvent).clientX - progress.getBoundingClientRect().left) / progress.offsetWidth
    video.currentTime = video.duration * value
  }

  handleFullscreenChange = () => {
    this.fullscreen = !!document.fullscreenElement
  }

  handleFullscreen = (e:Event) => {
    e.stopImmediatePropagation()
    e.stopPropagation()
    e.preventDefault()
    this.fullscreen = !this.fullscreen
    if (this.fullscreen)
      browser.requestFullscreen(this.el)
    else
      browser.exitFullscreen()
  }

  handleMuteUnmute = (e:Event) => {
    e.stopImmediatePropagation()
    e.stopPropagation()
    e.preventDefault()
    this.refs.video.muted = !this.muted
    this.muted = !this.muted
  }

  handlePlayPause = async (e:Event) => {
    e.stopImmediatePropagation()
    e.stopPropagation()
    e.preventDefault()
    if (this.playing) { this.refs.video.pause() } else {
      try {
        await this.refs.video.play()
      } catch (e) {
        console.warn('An error occured while playing video', this.el)
      }
    }
  }

  handlePause = () => {
    this.playing = false
  }

  handlePlay = () => {
    this.playing = true
  }

  handleTimeUpdate = () => {
    if (!this.playing)
      this.playing = !this.refs.video.paused

    const video = this.refs.video
    const progress = this.refs.progress
    const timecode = this.refs.timecode
    if (!progress || !timecode) return
    const value = (video.currentTime / video.duration)
    progress.style.transform = `scaleX(${value})`
    timecode.textContent = this.formatTime(video.currentTime).padStart(5, '0')
  }

  formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`.padStart(5, '0')
  }

  resize (): void {
    const isTabletPortrait = mqStore.tabletPortrait.get()
    if (isTabletPortrait) {
      if (this.refs.video.dataset.mobile && this.refs.video.src !== this.refs.video.dataset.mobile) {
        this.refs.video.src = this.refs.video.dataset.mobile
        if (this.refs.video.dataset.posterMobile) this.refs.video.poster = this.refs.video.dataset.posterMobile
        this.refs.video.innerHTML = ''
        if (this.refs.video.dataset.autoplay === 'true') this.refs.video.play()
      }
    } else {
      this.refs.video.removeAttribute('src')
      this.refs.video.append(...this.sources)
      if (this.refs.video.dataset.desktopPoster) this.refs.video.poster = this.refs.video.dataset.desktopPoster

      if (this.refs.video.dataset.autoplay === 'true') this.refs.video.play()
    }
  }
}

export default VideoPlayer
